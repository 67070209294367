
/*************************
* SEZNAMY
*************************/

/*************************
* MIXINY PRO ODRÁŽKY
*************************/

@mixin bullet($bg: #BCBCBC, $border: $bg, $top: -.08em) {
	&::before {
		@extend %border-box;
		position: relative;
		top: $top;
		margin-right: 7px;
		display: inline-block;
		content: encode-svg('<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="7px" height="7px" viewBox="0 0 7 7"><path fill="#{$bg}" stroke="#{$border}" stroke-miterlimit="10" d="M2.214,0.5h2.572C5.732,0.5,6.5,1.268,6.5,2.214v2.572C6.5,5.732,5.732,6.5,4.786,6.5H2.214C1.268,6.5,0.5,5.732,0.5,4.786V2.214C0.5,1.268,1.268,0.5,2.214,0.5z"/></svg>');
		vertical-align: middle;
		line-height: 0;
		overflow: hidden;

		@content;
	}
}

@mixin no-bullet {
	&::before {
		content: normal !important;
	}
}

/************************/

li {
	.odkazy .ui & li li,
	.aktuality2 &.bezobr li li,
	.utvary .ui &.o li li,
	.kategorie .ui & li li,
	.utvary .ui & li li,
	.galerie .ui & li li {
		@include bullet;
	}

	&.u,
	&.typsouboru {
		@include no-bullet;
		list-style-type: none !important;
	}

	.odkazy ol &::before {
		@include no-bullet;
	}
}

dl dt {
	font-size: rem(19px);
	color: $list-top-level-link-color;
	line-height: 1.1;
}

dl.kontakty .utvary {
	padding-top: 4px;
}

.ui {
	li > strong:first-child {
		font-family: $font;
		font-size: rem(23px);

		@include link {
			color: $list-top-level-link-color;
		}

		.utvary &,
		.bodkazy &,
		.souvisejiciodkazy & {
			font-family: $sans-serif;
			font-size: rem(16px);
			font-weight: bold;

			@include link {
				color: $color-base-link;
			}
		}

		img {
			font-size: rem(14px);
		}
	}

	li li > strong:first-child {
		.utvary &,
		.bodkazy &,
		.souvisejiciodkazy & {
			font-weight: normal;
		}
	}

	ul li > strong:first-child {
		font-family: $sans-serif;
		font-size: rem(16px);

		@include link {
			color: $color-base-link;
		}
	}

	li {
		clear: none;
		padding: 0;
		margin: 0 0 5px 0;

		strong {
			font-weight: normal;
			line-height: 1.2;

			dl.kontakty .utvary &,
			#stromutvaru .utvary &,
			.odkazy & {
				+ div {
					padding: 0;
					color: #666666;
					line-height: 1.4;
					font-style: italic;
				}
			}

			dl.kontakty .utvary & + div {
				margin-bottom: 10px;
			}
		}

		.ui {
			padding-top: 0;
			padding-bottom: 10px;

			.ui {
				padding-bottom: 0;
				
				.ui {
					padding-left: 18px;
				}
			}
		}

		li {
			margin: 5px 0;

			&::after {
				content: normal;
			}
		}
	}

	ol li {
		padding-left: 0;
	}

	div {
		font-weight: normal;
		margin-bottom: 2px;
		margin-top: 6px;
		padding-top: 0;
		padding-bottom: 0;
	}
}


dl.kontakty .utvary {
	ul.ui {
		margin-left: 0;
		margin-bottom: 15px;
	}

	li {
		strong {
			font-size: rem(15px);
			font-family: $sans-serif;

			@include link {
				color: $color-base-link;
			}
		}
	}
}


/**********************************
*	POVINNĚ ZVEŘEJŇOVANÉ INFORMACE
**********************************/

#isvs .hodnota {
	@extend .b2;

	li {
		@include no-bullet;
	}
}


/*********************
* VÝPISY DOKUMENTŮ
*********************/

.dok {
	ul {
		@extend %reset-list;
	}

	li {
		display: block;
		margin: 20px 0;
		padding: 0;

		&:first-child {
			margin-top: 8px;
		}
	}

	.n5-akce-typ,
	.dok-doplnek,
	.ktg,
	strong + span,
	.vd-priloha {
		font-size: rem(15px);
		color: #444444;

		@include link {
			color: $bileplochyodkazy;
		}
	}

	.vd-priloha {
		font-family: $sans-serif;
	}

	strong {
		font-weight: normal;

		img {
			margin: 3px 20px 6px 0;
            float: left;
            max-width: 265px;
		}

		a {
			font-size: rem(30px);
			font-family: $font;
			color: $aktuality1nadpisy;
			line-height: 1.1;
		}
	}
}

#zpravodajstvi {
	ul.inline {
		padding: 0;
		margin: 20px 0;
	}
}


#kalendarAkci .dok {
	li.u {
		padding-left: 150px;

		strong img {
			margin-left: -150px;
		}
	}
}


/** DOKUMENTY NA TITULCE **/

.wrapper-novinky {
	.dok {
		ul {
			@extend %sf;
		}

		li {
			box-sizing: border-box;
			margin-top: 0;
			padding-top: 0;
			margin-bottom: 25px;
			@include grid(600px 2 3%, 1024px 4);

			> strong {
				text-align: left;
				display: block;

				img {
					display: none;
				}
			}

			> div {
				margin-top: 8px;
			}
			
			@include bp("> 600px") {
				border-top: 7px $linky solid;
				padding-top: 25px;

				> strong {
					padding-bottom: 5px;
					border-bottom: 1px $linky solid;
					text-align: center;

					img {
                        display: block;
                        float: none;
						margin-right: 0;
						width: 100%;
						max-width: 100%;
					}
				}

				> div {
					margin-top: 15px;
				}
			}


			.dok-doplnek {
				font-style: italic;
			}

			img {
				margin-top: 0;
			}
		}
	}

	@include bp(">= 650px") {
		.skryty {
			display: none;
		}
	}
}

.horizontal {
	.dok {
		ul {
			@include bp("> 500px") {
				background-color: #ffffff;
				border-left: 10px $deskalinky solid;
			}

			li {
				box-sizing: border-box;
				margin: 0;
				padding: 30px 0 0 0;
				width: 100%;

				&:last-child {
					padding-bottom: 30px;
				}

				.dok-datum,
				.dok-telo {
					box-sizing: border-box;
				}

				.dok-datum {
					padding: 1px 0;
					color: #252525;
					display: block;
					font-family: $font;
					font-size: rem(22px);
				}


				.dok-doplnek {
					font-style: italic;
				}

				@include bp("> 500px") {
					display: table;
					padding: 0;

					.dok-datum,
					.dok-telo {
						box-sizing: border-box;
						display: table-cell;
						vertical-align: top;
					}
					
					.dok-datum {
						font-size: rem(25px);
						width: 5.5em;
						border-right: 1px $deskalinky solid;
						padding: 14px 14px 14px 3%;
					}

					.dok-telo {
						padding: 14px 25px 14px 5%;
					}

					&:first-child {
						.dok-telo,
						.dok-datum {
							padding-top: 45px;
						}
					}

					&:last-child {
						padding-bottom: 0;

						.dok-telo {
							padding-bottom: 25px;
						}
					}
				}
			}
		}
	}
}


.wrapper-uredni-deska {
	background: #e4e4e2 none no-repeat 50% 50%;

	@include bp("> 1024px") {
		background-image: url(images/bg-uredni-deska.jpg);
	}

	.dok strong a {
		font-size: rem(25px);
	}

	h2.cvi,
	.horizontal {
		display: none;

		@include bp("> 600px") {
			display: block;
		}
	}
}


/*******************************
* OSNOVA S UPOUTÁVKAMI V POZADÍ	
*******************************/

.obrodkazy {
	.ui {
		@extend %reset-list;
	}

	li {
		@include no-bullet;
		@extend %reset;
		display: block;
		margin-bottom: 13px;
		background-image: none;
		position: relative;
		
		@include limit-items(3);
	}

	a { 
		font-size: rem(20px);
		font-family: $sans-serif;
		background-repeat: no-repeat;
		background-position: 0 50%;
		padding-left: 65px;
		display: table;
		height: 1em;
		min-height: 48px;
	}

	.mtext {
		display: table-cell;
		vertical-align: middle;
	}

	strong {
		font-weight: normal;
	}

	@include link {
		text-decoration: none;
	}

	@include link-over {
		text-decoration: underline;
	}
}


/*****************************
* VÝPIS AKCÍ S CEDULKAMI
*****************************/

#vypisakci {
	h3.cvi {
		font-size: rem(25px);
		margin-bottom: 10px;
	}

	.dok {
		li {
			display: table;
			margin: 0 0 44px 0;
			width: 100%;

			strong {
				@include link {
					color: $event-link-color;
				}
			}
		}

		.datum,
		.prazdnyDatum {
			display: table-cell;
			vertical-align: top;
			width: 5.52em;
			text-align: center;
		}

		.den,
		.mesic {
			display: block;
			line-height: 1.2;
		}

		.den {
			font-size: rem($event-day-size);
			font-weight: bold;
			background: $event-day-background;
			color: $event-day-text;
			padding: .05em 0;
			@include radius(top, 3px);
		}

		.mesic {
			font-size: rem($event-month-size);
			background: $event-month-background;
			color: $event-month-text;
			text-transform: lowercase;
			padding: .5em 0;
			@include radius(bottom, 3px);
			border: {
				style: solid;
				color: $bunkyTabulekAktualni;
				width: 0 1px 1px 1px;
			}
		}

		.denPredlozka,
		.rok, 
		.den .tecka {
			position: absolute;
			left: -9999px;
			right: 9990px;
		}

		.prazdnyDatum {
			background: transparent;
		}

		.obsahAkce {
			display: table-cell;
			padding-right: 15px;
			padding-left: 15px;

			.misto {
				color: $akcedoplnkovytext;	
			}

			.typAkce {
				font-style: italic;
				color: $akcedoplnkovytext;	

				@include link {
					color: $akceodkazy;
				}
			}
		}
	}
}



/*****************************
* MODUL DALŠÍ ZDROJE NA TITULCE
*****************************/

.titulodkazy {
	&:not(:first-child) {
		margin-top: 35px;
	}

	.ui {
		@extend %reset-list;
	}

	li {
		//@include no-bullet;
		display: block;
		background-image: none;
		margin: 4px 0;
		padding: 0 0 0 28px;
		position: relative;

		&::before {
			content: "";
			width: 8px;
			height: 8px;
			border-radius: 50%;
			background-color: $vysokapatasymboly;
			position: absolute;
			top: .5em;
			left: 2px;
		}

		a {
			font-family: $sans-serif;
			font-size: rem(16px);
		}

		strong:first-child {
			@include link {
				color: $vysokapataodkazy;
			}
		}

		div {
			margin: 2px 0 0 0;
		}
	}

	strong {
		font-weight: normal;
	}
}

.dok li.urd-line {
	position: relative;
	margin: 0;
	padding: 15px 20px;

	&:nth-child(even) {
		background-color: #ededed;
	}

	&::after {
		content: normal;
	}

	.urd-left {
		position: relative;
	}

	strong a::after {
		@include fill;
		content: "";
	}

	@include bp("> 800px") {
		display: table;
		box-sizing: border-box;
		width: 100%;

		.urd-left,
		.urd-right {
			display: table-cell;
		}

		.urd-left {
			padding-right: 1.5em;
		}

		.urd-right {
			width: 13em;
		}
	}
}


