/*oE (vsechny stranky)*/
$zahlavipozadi: #F4F4F4;
$zahlavitext: #272727;
$nazev: #001A56;
$dodatek: #000000;
$zahlaviodkazy: #000000;
$vyhledavaniobrys: #97CC04;
 
$hlavninadpisy: #001A56;
 
$menupozadi: #212F64;
$menuzvyraznenipozadi: #9CD109;
$menutlacitkapozadi: #001A56;
$menutlacitkatext: #FFFFFF;
$menuzvyraznenitext: #000000;
 
$upozornenipozadi: #A1DB22;
$upozorneninadpisy: #001A56;
$upozornenitext: #000000;
$upozorneniodkazy: #001A56;
 
$aktuality1nadpisy: #001A56;
$linky: #97CC04;
$bileplochyodkazy: #608006;
 
$deskalinky: #97CC04;
 
$kalendarakcipozadi: #9CD109;
$akcehlavninadpis: #001a56;
$akcenadpisy: #001A56;
$kalendarakcitext: #000000;
$akcedoplnkovytext: #000000;
$odkazyTabulekOstatni: #000000;
$odkazyTabulekAktualni: #FFFFFF;
$bunkyTabulekOstatni: #FFFFFF;
$bunkyTabulekAktualni: #001A56;
$akcetlacitkapozadi: #001A56;
$akcetlacitkatext: #FFFFFF;
$akceodkazy: #272E00;
 
$formularepozadi: #F4F4F4;
$formularetext: #272727;
$formularenadpis: #001A56;
$polepozadi: #FFFFFF;
$poletext: #2C2C1B;
$poleobrysy: #97CC04;
 
$tlacitkapozadi: #001A56;
$tlacitkatext: #FFFFFF;
 
$vysokapatapozadi: #001A56;
$vysokapatatext: #FFFFFF;
$vysokapataodkazy: #BAF459;
$vysokapatanadpisy: #FFFFFF;
$vysokapatasymboly: #97CC04;
 
$patapozadi: #9CD109;
$patatext: #000000;
$pataodkazy: #000000;
