
/*******************
* NADPISY
*******************/

h1.cvi {
	font-size: rem(16px);
	padding: 0;
	line-height: 1.1;
	margin: 0 20px;
	font-weight: normal;
	vertical-align: bottom;
	
	@include bp("> 600px") {
		padding-top: 18px;
	}

	strong {
		display: block;
		font-family: $font;
		font-size: rem(52px);
		line-height: .75;
		margin: 0 8px 10px 0;
		color: $nazev;
		text-shadow: 1px 1px 2px rgba(#000000, .3);

		@include bp("> 600px") {
			font-size: rem(64px);
			margin-bottom: 0;
		}

		@include bp("> 900px") {
			font-size: rem(82px);
		}
	}

	@include bp("> 450px") {
		margin-left: 98px;

		.bez-znaku & {
			margin-left: 0;
		}

		strong {
			display: inline;
		}
	}

	.hsub { 
		white-space: nowrap;
		color: $dodatek;
	}
}

h2.cvi,
h2.cist,
h3.cvi,
h3.ud,
#prohlaseni h3,
h4 {
	font-family: $font;
}

h2.cvi,
.poz h2.cist {
	font-size: rem(42px);
	color: $hlavninadpisy;
	font-weight: 600;
	margin: 0 0 18px 0;
	padding: 0;
	line-height: 1;
	text-align: center;

	@include bp("> 700px") {
		font-size: rem($h2-size);
	}

	&.cvi--mensi {
		font-size: rem(40px);
	}
}

#zahlavi h2.cvi {
	margin: 0 0 30px 0;
	padding: 0;
}

// Hlavní nadpisy na podstránce
#zahlavi h2.cvi,
#zalozkynadpis h2.cvi {
	font-size: rem(40px);

	@include bp("> 800px") {
		text-align: left;
		font-size: rem(60px);
	}
}

h3 {
	.editor &,
	&.cvi,
	&.ud,
	#prohlaseni & {
		font-size: rem($h3-size);
		font-weight: normal;
		margin: 25px 0 5px 0;
		padding: 8px 20px 4px 0;
		line-height: 1.2;
	}
}

h4 {
	font-size: rem($h4-size);
	line-height: 1.2;
}

h4.ud + .udz {
	margin-top: 28px;
}